import { Button, Table } from '@/design-system'
import { SigningKey } from '@/services/merchant-api'
import React from 'react'
import { HoverCardToken } from './hover-card-token'
import { ExpiresAt } from './expires-at'
import { HoverCardVerificationKey } from './hover-card-verification-key'
import { HoverCardFingerprint } from './hover-card-fingerprint'
import { Enabled } from './enabled'

interface SigningKeysTableRowProps {
    signingKey: SigningKey
    onEditClick: () => void
}

export const SigningKeysTableRow: React.FC<SigningKeysTableRowProps> = (props) => {
    const { signingKey, onEditClick } = props

    const { id, token, verification_key, fingerprint, expires_at, enabled } = signingKey

    const handleOnEditClick = () => {
        onEditClick()
    }

    return (
        <Table.Row key={id} stripeShade="100">
            <Table.Cell>
                <HoverCardToken token={token} />
            </Table.Cell>
            <Table.Cell>
                <ExpiresAt expiresAt={expires_at ? expires_at : '-'} />
            </Table.Cell>
            <Table.Cell>
                <HoverCardVerificationKey verificationKey={verification_key} />
            </Table.Cell>
            <Table.Cell>
                <HoverCardFingerprint fingerprint={fingerprint} />
            </Table.Cell>
            <Table.Cell>
                <Enabled enabled={enabled} />
            </Table.Cell>
            <Table.Cell justify="end">
                <Button testid="signing-key-edit-button" variant="link" size="none" onClick={handleOnEditClick}>
                    Edit
                </Button>
            </Table.Cell>
        </Table.Row>
    )
}
