import { Tooltip, Text, TextProps } from '@/design-system'
import React from 'react'

interface TruncatedProps extends TextProps {
    children: string | number
}

const TruncatedText: React.FC<TruncatedProps> = (props) => {
    const { children, overflow, ...otherTextProps } = props

    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <a>
                    <Text overflow="truncate" {...otherTextProps} testid="integration-name">
                        {children}
                    </Text>
                </a>
            </Tooltip.Trigger>
            <Tooltip.Content>{children}</Tooltip.Content>
        </Tooltip>
    )
}

export { TruncatedText }
