import { Card, Table } from '@/design-system'
import React from 'react'

interface SigningKeysTableProps {
    children: React.ReactNode
}

export const SigningKeysTable: React.FC<SigningKeysTableProps> = (props) => {
    const { children } = props

    return (
        <Card variant="surface" color="neutral">
            <Card.Body>
                <Table>
                    <Table.Header backgroundColor="neutral" backgroundShade="50">
                        <Table.Row>
                            <Table.Head>Token</Table.Head>
                            <Table.Head>Expires at</Table.Head>
                            <Table.Head>Verification key</Table.Head>
                            <Table.Head>Fingerprint</Table.Head>
                            <Table.Head>Status</Table.Head>
                            <Table.Head justify="end">Actions</Table.Head>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body testid="signing-key-table-body">{children}</Table.Body>
                </Table>
            </Card.Body>
        </Card>
    )
}
