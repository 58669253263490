import { VariantProps, cva } from 'class-variance-authority'

export type ContentStyles = VariantProps<typeof styles.content>

export const styles = {
    content: cva([
        'w-full',
        'z-[2000000]',
        'items-center',
        'justify-center',
        'rounded-lg',
        'bg-white',
        'px-2',
        'py-3',
        'text-black',
        'text-center',
        'shadow-md',
        'outline-none',
        'dark:bg-gray-950',
        'dark:text-white'
    ])
}
