import { VariantProps, cva } from 'class-variance-authority'

export type TableStyles = VariantProps<typeof styles.table>
export type RowStyles = VariantProps<typeof styles.row>
export type HeaderStyles = VariantProps<typeof styles.header>
export type HeadStyles = VariantProps<typeof styles.head>
export type BodyStyles = VariantProps<typeof styles.body>
export type CellStyles = VariantProps<typeof styles.cell>

export const styles = {
    table: cva([
        'table-auto',
        'min-w-full',
        'divide-y-2',
        'divide-neutral-200',
        'rounded-lg',
        'text-sm',
        'dark:text-neutral-200',
        'border-spacing-0',
        'bg-transparent'
    ]),
    row: cva(
        ['border-none', 'odd:bg-neutral-50', 'hover:bg-neutral-100', 'dark:odd:bg-gray-800', 'dark:hover:bg-gray-700'],
        {
            variants: {
                stripeColor: {
                    neutral: 'odd:bg-neutral-50'
                },
                stripeShade: {
                    '50': 'odd:bg-neutral-50 hover:bg-neutral-100',
                    '100': 'odd:bg-neutral-100 hover:bg-neutral-200 dark:even:bg-transparent dark:odd:bg-gray-800 dark:hover:bg-gray-900'
                }
            },
            compoundVariants: [
                {
                    stripeColor: 'neutral',
                    stripeShade: '50',
                    class: 'odd:bg-neutral-50 hover:bg-neutral-100 dark:odd:bg-gray-800 dark:hover:bg-gray-700'
                },
                {
                    stripeColor: 'neutral',
                    stripeShade: '100',
                    class: 'odd:bg-neutral-100 hover:bg-neutral-200'
                }
            ]
        }
    ),
    header: cva('', {
        variants: {
            backgroundColor: {
                white: 'bg-white',
                neutral: 'bg-neutral'
            },
            backgroundShade: {
                '50': 'bg-neutral-50',
                '100': 'bg-neutral-100'
            }
        }
    }),

    head: cva(
        [
            'whitespace-nowrap',
            'px-4',
            'py-1',
            'text-left',
            'text-[9px]',
            'font-medium',
            'text-neutral-400',
            'bg-white',
            'uppercase',
            'dark:bg-gray-900'
        ],
        {
            variants: {
                backgroundColor: {
                    white: 'bg-white',
                    neutral: 'bg-neutral'
                },
                backgroundShade: {
                    '50': 'bg-neutral-50',
                    '100': 'bg-neutral-100'
                },
                justify: {
                    start: 'text-left',
                    end: 'text-right',
                    center: 'text-center'
                }
            },
            compoundVariants: [
                {
                    backgroundColor: 'neutral',
                    backgroundShade: '50',
                    class: 'bg-neutral-50 dark:bg-gray-700'
                },
                {
                    backgroundColor: 'neutral',
                    backgroundShade: '100',
                    class: 'bg-neutral-100'
                }
            ]
        }
    ),
    body: cva('border-none divide-y'),
    cell: cva('whitespace-nowrap px-4 py-2 first:rounded-l-lg last:rounded-r-lg', {
        variants: {
            justify: {
                start: 'text-left',
                end: 'text-right',
                center: 'text-center'
            }
        }
    })
}
