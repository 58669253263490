import { Box, Collapsible, Grid, Separator } from '@/design-system'
import { Partner } from '@/services/merchant-api'
import { TruncatedText } from '@/ui/truncated-text'
import React from 'react'
import { IntegrationRowDetails } from './integrations-row-details'
import { IntegrationRowEdit } from './integration-row-edit'
import { IntegrationRowContent } from './integration-row-content'

interface IntegrationRowProps {
    children: React.ReactNode
    name: string
    description: string
    transactionRules: string
    partners: Partner[]
    merchantPaymentGatewayId?: string
    isLast?: boolean
    onEditClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const IntegrationRow: React.FC<IntegrationRowProps> = (props) => {
    const { children, name, description, transactionRules, partners, isLast, onEditClick } = props

    const technicalPartner = partners.find((partner) => partner.technical)
    const supportPartner = partners.find((partner) => partner.support)

    const technicalPartnerName = technicalPartner?.name ? technicalPartner.name : '-'
    const supportPartnerName = supportPartner?.name ? supportPartner.name : '-'

    const paddingTop = '6'
    const paddingBottom = isLast ? '3' : '6'

    return (
        <Collapsible asChild>
            <div className="contents">
                <Collapsible.Trigger asChild testid="integration-trigger">
                    <div className="contents">
                        <Grid.Item align="center" columnSpan="4">
                            <Separator />
                        </Grid.Item>
                        <Grid.Item align="center">
                            <Box pt={paddingTop} pb={paddingBottom}>
                                <IntegrationRowDetails
                                    technicalPartnerName={technicalPartnerName}
                                    supportPartnerName={supportPartnerName}
                                />
                            </Box>
                        </Grid.Item>
                        <Grid.Item align="center">
                            <Box pt={paddingTop} pb={paddingBottom}>
                                <TruncatedText color="neutral">{name}</TruncatedText>
                            </Box>
                        </Grid.Item>
                        <Grid.Item align="center">
                            <Box pt={paddingTop} pb={paddingBottom}>
                                <TruncatedText color="neutral">{description}</TruncatedText>
                            </Box>
                        </Grid.Item>
                        <Grid.Item align="center" justify="end">
                            <Box pt={paddingTop} pb={paddingBottom}>
                                <IntegrationRowEdit onEditClick={onEditClick} />
                            </Box>
                        </Grid.Item>
                    </div>
                </Collapsible.Trigger>
                <Collapsible.Content asChild>
                    <Grid.Item align="center" columnSpan="4">
                        <Box mt="4" mb="10">
                            <IntegrationRowContent transactionRules={transactionRules}>
                                {children}
                            </IntegrationRowContent>
                        </Box>
                    </Grid.Item>
                </Collapsible.Content>
            </div>
        </Collapsible>
    )
}

export { IntegrationRow }
