import type { ReactNode } from 'react'
import React, { useContext } from 'react'
import { cn, isTextSelected } from '../utils'
import { BodyStyles, CellStyles, HeadStyles, HeaderStyles, RowStyles, TableStyles, styles } from './styles'

interface TableProps extends TableStyles {
    testid?: string
    children: ReactNode
}

const Table = (props: TableProps) => {
    const { testid, children } = props

    const classnames = cn(styles.table())

    return (
        <table data-cy={testid} className={classnames}>
            {children}
        </table>
    )
}

interface RowProps extends RowStyles {
    testid?: string
    children: ReactNode
    onClick?: (_e: React.MouseEvent) => void
}

const Row: React.FC<RowProps> = (props) => {
    const { testid, children, onClick, stripeColor, stripeShade } = props

    const handleOnClick = (e: React.MouseEvent) => {
        if (onClick) {
            if (!isTextSelected()) {
                onClick(e)
            }
        }
    }

    const classnames = cn(styles.row({ stripeColor, stripeShade }), onClick, onClick && 'cursor-pointer')

    return (
        <tr data-cy={testid} onClick={handleOnClick} className={classnames}>
            {children}
        </tr>
    )
}

interface HeaderContext {
    backgroundColor?: HeaderStyles['backgroundColor']
    backgroundShade?: HeaderStyles['backgroundShade']
}

const defaultValues = {}
const HeaderContext = React.createContext<HeaderContext>(defaultValues)

interface Header extends HeaderStyles {
    testid?: string
    children: ReactNode
}

const Header: React.FC<Header> = (props) => {
    const { testid, children, backgroundColor, backgroundShade } = props

    const value = { backgroundColor, backgroundShade }

    return (
        <HeaderContext.Provider value={value}>
            <thead data-cy={testid}>{children}</thead>
        </HeaderContext.Provider>
    )
}

type HeadStylesOmitted = Omit<HeadStyles, 'backgroundColor' | 'backgroundShade'>
interface HeadProps extends HeadStylesOmitted {
    testid?: string
    children: ReactNode
    colSpan?: number
}

const Head: React.FC<HeadProps> = (props) => {
    const { testid, children, colSpan, justify } = props

    const { backgroundColor, backgroundShade } = useContext(HeaderContext)

    const classnames = cn(styles.head({ justify, backgroundColor, backgroundShade }))

    return (
        <th data-cy={testid} className={classnames} colSpan={colSpan}>
            {children}
        </th>
    )
}

interface BodyProps extends BodyStyles {
    testid?: string
    children: ReactNode
}

const Body: React.FC<BodyProps> = (props) => {
    const { testid, children } = props

    const classnames = cn(styles.body())

    return (
        <tbody data-cy={testid} className={classnames}>
            {children}
        </tbody>
    )
}

interface CellProps extends CellStyles {
    testid?: string
    children: ReactNode
    colSpan?: number
}

const Cell: React.FC<CellProps> = (props) => {
    const { testid, children, colSpan, justify } = props

    const classnames = cn(styles.cell({ justify }))

    return (
        <td data-cy={testid} className={classnames} colSpan={colSpan}>
            {children}
        </td>
    )
}

Table.Row = Row
Table.Header = Header
Table.Head = Head
Table.Body = Body
Table.Cell = Cell

export { Table }
