import { Box, Button, Collapsible, Flex } from '@/design-system'
import React from 'react'

interface IntegrationRowEditProps {
    onEditClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const IntegrationRowEdit: React.FC<IntegrationRowEditProps> = (props) => {
    const { onEditClick } = props

    return (
        <Flex align="center" justify="end" gap="1" flex="auto">
            <Button testid="integration-edit-button" variant="link" onClick={onEditClick} asChild>
                <span>Edit</span>
            </Button>
            <Box mb="1">
                <Collapsible.Chevron />
            </Box>
        </Flex>
    )
}

export { IntegrationRowEdit }
