import React from 'react'
import { cn } from '../utils'
import { Styles, styles } from './styles'
import { Slot } from '@radix-ui/react-slot'

interface BoxProps extends Styles {
    testid?: string
    children?: React.ReactNode
    asChild?: boolean
}

export const Box: React.FC<BoxProps> = (props) => {
    const {
        testid,
        children,
        asChild,
        display,
        flex,
        grow,
        shrink,
        basis,
        alignSelf,
        width,
        m,
        mx,
        my,
        mt,
        mb,
        ml,
        mr,
        p,
        px,
        py,
        pt,
        pb,
        pl,
        pr
    } = props

    const classnames = cn(
        styles({
            display,
            flex,
            grow,
            shrink,
            basis,
            alignSelf,
            width,
            m,
            mx,
            my,
            mt,
            mb,
            ml,
            mr,
            p,
            px,
            py,
            pt,
            pb,
            pl,
            pr
        })
    )
    const Component = asChild ? Slot : 'div'

    return (
        <Component data-cy={testid} className={classnames}>
            {children}
        </Component>
    )
}
